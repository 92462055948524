.item {
    margin: 0px;
    display: inline-grid;
    align-self: auto;
    font-size: 0;
    height: 48px;
    cursor: pointer;
  }

  .item-image {  
    opacity: .25;
    font-size: 0;
    height: 100%;
    width: 100%;    
  }

.increment-controls {
  padding-top: 10px;  
}

.item.incrementing {
  grid-template-columns: 1fr;
  height: 50px;
}

.available {
  border-color: rgb(255, 255, 255)
}

.unavailable {
  border-color: rgb(33, 33, 33)
}

.character-count {
  background-image: url(./icons/leo.gif);
  background-repeat: no-repeat;
  background-position-x: center;
  font: outline;
  color: white;
  font-weight: 600;
  font-size:16px;
  text-align: end;
  padding-top: 30px;
  padding-left: 40px;
}

.magicite {
  background-image: url(./icons/magicite.gif);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  font: outline;
  color: white;
  font-weight: 600;
  font-size:16px;
  text-align: end;
  padding-top: 30px;
}

.incrementor {

  font-size: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}


.groups {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;    
  padding-left: 20px;
  padding-right: 20px;
  max-width: 566px;
}

@media only screen and (max-width: 600px) {
  .groups {
    grid-template-columns: 1fr 1fr 1fr;
  }
}



.wob {
  background-color: darkblue;
}

.wor {
  background-color:darkred;
}
.both {
  background-color: darkgreen;
}

  .tracker-group {
    border-style: solid;
    border-width: 2px;
    border-radius: 17px;
    overflow: hidden;
}
  .tracker-group-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
  }

  .group-item {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
  }

  .collected {
    opacity: 1;
  }

  .trackers {
    background-color: black;
    font-size: 0;
  } 
 

  .hidden {
    display: none
  }
